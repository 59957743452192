<template>
    <div>
        <v-row class="table-actions-row">
            <v-col sm="4" cols="12" class="pl-0">
                <breadcrumbs :items="crumbs"> </breadcrumbs>
            </v-col>
            <v-col sm="8" class="d-flex justify-end align-center">
                <CButton
                    @click="
                        clearCurrentItem()
                        showEntityModal = true
                    "
                    class="add-product-modal"
                    color="info"
                    ><i class="la la-plus"></i>
                    Добавить агентство
                </CButton>
            </v-col>
        </v-row>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    ref="grid"
                    class="agency"
                    :resizable="true"
                    :sortable="true"
                    :filterable="true"
                    :pageable="gridPageable"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <actions-dropdown
                                    :actions="[
                                        {
                                            text: 'Редактировать',
                                            icon: 'la la-pencil',
                                            handler: () => {
                                                setCurrentItem(dataItem)
                                                showEntityModal = true
                                            },
                                        },
                                        {
                                            text: 'Удалить',
                                            icon: 'la la-trash',
                                            handler: () =>
                                                deleteEntity(dataItem),
                                        },
                                    ]"
                                />
                            </div>
                        </td>
                    </template>
                </Grid>
            </intl>
        </localization>
        <agency-modal
            v-if="showEntityModal"
            :current-item="currentItem"
            :show-dialog="showEntityModal"
            @click:outside="showEntityModal = false"
            @stored="entityStored($event)"
            @close="showEntityModal = false"
        ></agency-modal>
    </div>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin'
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin'
    import { Grid } from '@progress/kendo-vue-grid'
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl'
    import { DropDownList } from '@progress/kendo-vue-dropdowns'
    import ActionsDropdown from '@/views/grid_elements/ActionsDropdown'
    import { showConfirm } from '@/lib/swal2'
    import Breadcrumbs from '@/views/components/breadcrumbs'
    import { showMessage } from '@/lib/toasted'
    import AgencyCreateUpdateModal from '../modals/AgencyCreateUpdateModal'
    import '@/views/grid_elements/translations'
    import api from '@/api'
    import { toDataSourceRequestString } from '@progress/kendo-data-query'

    export default {
        name: 'agencies',
        components: {
            Grid: Grid,
            dropdownlist: DropDownList,
            breadcrumbs: Breadcrumbs,
            'agency-modal': AgencyCreateUpdateModal,
            localization: LocalizationProvider,
            intl: IntlProvider,
            ActionsDropdown,
        },
        mixins: [TableMixin, ResizableTableMixin],
        mounted() {
            this.getData()
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                gridPageable: { pageSizes: true },
                total: 7,
                expandedItems: [],
                columns: [
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'name',
                        title: 'Название',
                    },
                    {
                        field: 'email_for_notification',
                        title: 'E-mail',
                    },
                    {
                        field: 'agency_name',
                        title: 'Тип агенства',
                    },
                ],
            }
        },
        methods: {
            fetchData: api.agencies.getAgencies,
            async deleteEntity(item) {
                const { isConfirmed } = await showConfirm(
                    'Вы действительно хотите удалить данный элемент?'
                )

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.agencies.deleteAgency(item.id)
                    showMessage(status, message)

                    await this.getData()
                }
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ]
            },
        },
    }
</script>

<style scoped>
    /*.k-grid-header col:last-of-type {*/
    /*  width: 10%*/
    /*}*/

    /*.k-grid-table col:last-of-type {*/
    /*  width: 10%*/
    /*}*/
</style>
