<template>
  <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }">
    <v-dialog :value="showDialog" :persistent="false" width="600" @input="closeDialog">
      <v-card>
        <v-card-title class="headline">
          <span><i class="fa fa-user"></i> {{  currentItem ? 'Редактирование агентство' : 'Добавить агентство'  }}</span>
        </v-card-title>

        <v-card-text>
          <form>
            <v-layout column>
              <ValidationProvider name="agency_type" rules="required" v-slot="{ invalid, validated }">
                <v-select v-model="agency_type" :items="agency_types" item-value="type" item-text="name"
                  label="Тип агенства *" hide-details="auto" required :error="invalid && validated"></v-select>
              </ValidationProvider>
              <ValidationProvider v-if="agency_type === 2" name="tpp_type" rules="required" v-slot="{ invalid, validated }">
                <v-select v-model="tpp_type" :items="tpp" item-value="type" item-text="name"
                  label="Площадка *" hide-details="auto" required :error="invalid && validated"></v-select>
              </ValidationProvider>
              <ValidationProvider name="name" rules="required" v-slot="{ invalid, validated }">
                <v-text-field :error="invalid && validated" label="Название *" hide-details="auto" v-model="name">
                </v-text-field>
              </ValidationProvider>
              <ValidationProvider name="email_for_notification" rules="required|email" v-slot="{ invalid, validated }">
                <v-text-field :error="invalid && validated" label="Email для отправки уведомлений *" hide-details="auto"
                  v-model="email_for_notification"></v-text-field>
              </ValidationProvider>
            </v-layout>
          </form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
          <CButton :disabled="invalid" @click="handleSubmit(submit)" color="success">
            <i class="fa fa-save mr-2"></i>
            <span>Сохранить</span>
          </CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import EntityModalMixin from '@/views/grid_elements/mixins/EntityModalMixin';
import api from '@/api';

export default {
  name: "agency-modal",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  mixins: [ EntityModalMixin ],
  data ()
  {
    let state = {
      name: '',
      agency_type: null,
      email_for_notification: '',
      agency_types: [
        {
          name: 'Агентство по товарам',
          type: 1
        }, {
          name: 'Агентство по по поездкам',
          type: 2
        },
        {
          name: 'Агентство по обучению',
          type: 6
        },
        {
          name: 'Агентство по списанию',
          type: 7
        }
      ],
      tpp: [ {
        name: 'ТПП г. Пермь',
        type: 1
      }, {
        name: 'ТПП г. Алматы',
        type: 2
      },
      {
        name: 'Завод г. Торжок',
        type: 3
      },
        {
          name: 'Завод г. Тюмень',
          type: 4
        }
      ]
    };

    if ( this.currentItem )
    {
      state = {
        ...state,
        ...this.currentItem
      }
    }

    return state;
  },
  methods: {
    createEntity: api.agencies.createAgency,
    updateEntity: api.agencies.updateAgency,
  },
  computed: {
    data ()
    {
      return {
        id: this.id,
        agency_type: this.agency_type,
        tpp_type: this.tpp_type,
        name: this.name,
        email_for_notification: this.email_for_notification
      }
    }
  }
}
</script>
